import { getDictLists, filterMultiple } from '@/filters/fromDict'
// 个人担保
export const personalTabel = (content) => {
  return [
    {
      prop: 'code',
      type: 'expand'
    },
    {
      label: '序号',
      prop: 'index',
      width: '80px'
    },
    {
      label: '姓名',
      prop: 'personnalName'
    },
    {
      label: '电话号码',
      prop: 'mobilePhoneNo'
    },
    {
      label: '职位',
      prop: 'dutiesName'
    },
    {
      label: '人员类别',
      prop: 'personType',
      formatter: row => {
        return filterMultiple('PERSONNEL_TYPE', row.personType, 'String')
      }
    },
    {
      label: '操作',
      prop: 'action'
    }
  ]
}
// 个人担保子表格
export const personalFormChildren = (content) => {
  return [

    {
      label: '姓名',
      prop: 'personnalName'
    },
    {
      label: '身份证',
      prop: 'idCard'
    },
    {
      label: '电话号码',
      prop: 'mobilePhoneNo'
    },
    {
      label: '关系',
      prop: 'contactType',
      formatter: row => {
        const [obj] = getDictLists('PERSON_CONTACT_TYPE').filter(item => item.dictId === row.contactType.toString())
        return obj ? obj.dictName : '-'
      }
    },
    {
      label: '家庭地址',
      prop: 'address'
    },
    {
      label: '操作',
      prop: 'action'
    }
  ]
}
// 新增个人担保查询
export const addPersonalFrom = (content) => {
  return [
    {
      label: '姓名',
      prop: 'personnerName',
      attrs: {
        placeholder: '请输入',
        maxlength: 100
      },
      on: {
        input: data => {
          // eslint-disable-next-line no-useless-escape
          content.queryParas.personnerName = data.replace(/[0-9]|&quot;|&lt;|&gt;|[\（\）\……\~\`\·\|\【\】\》\《\'\!\！\{\}\#\$\￥\%\^\&\*\(\)\[\]\:\;\：\；\\\‘\“\”\’\,\，\.\。\/\、\?\？\_\-\——\=\+]|@|/g, '').replace(/\s+/g, '')
        }
      }
    },
    {
      label: '身份证',
      prop: 'idCard',
      attrs: {
        placeholder: '请输入',
        maxlength: 18
      },
      on: {
        input: data => {
          // eslint-disable-next-line no-useless-escape
          content.queryParas.idCard = data.replace(/[\u4E00-\u9FA5]|&quot;|&lt;|&gt;|[\（\）\……\~\`\·\|\【\】\》\《\'\!\！\{\}\#\$\￥\%\^\&\*\(\)\[\]\:\;\：\；\\\‘\“\”\’\,\，\.\。\/\、\?\？\_\-\——\=\+]|@|/g, '').replace(/\s+/g, '')
        }
      }
    },
    {
      label: '电话号码',
      prop: 'mobilePhoneNo',
      attrs: {
        placeholder: '请输入',
        maxlength: 11
      },
      on: {
        input: data => {
          // eslint-disable-next-line no-useless-escape
          content.queryParas.mobilePhoneNo = data.replace(/[\u4E00-\u9FA5]|[a-zA-Z]|&quot;|&lt;|&gt;|[\（\）\……\~\`\·\|\【\】\》\《\'\!\！\{\}\#\$\￥\%\^\&\*\(\)\[\]\:\;\：\；\\\‘\“\”\’\,\，\.\。\/\、\?\？\_\-\——\=\+]|@|/g, '').replace(/\s+/g, '')
        }
      }
    }
  ]
}
// 个人担保表格
export const tableConfig = (content) => {
  return [
    {
      type: 'selection',
      width: '55px'
    },

    {
      label: '序号',
      prop: 'index'
    },
    {
      label: '姓名',
      prop: 'personnerName'
    },
    {
      label: '身份证',
      prop: 'idCard'
    },
    {
      label: '电话号码',
      prop: 'mobilePhoneNo'
    },
    {
      label: '职位',
      prop: 'dutiesName'
    },
    {
      label: '人员类别',
      prop: 'personTypeName'
    }
  ]
}
export const newColumns = (content) => {
  return [
    {
      label: '',
      prop: 'code',
      type: 'expand',
      HiddenOverflow: true
    },
    {
      type: 'selection',
      width: '55px'
    },
    {
      label: '序号',
      prop: 'index'
    },
    {
      label: '姓名',
      prop: 'personnerName'
    },
    {
      label: '身份证',
      prop: 'idCard'
    },
    {
      label: '电话号码',
      prop: 'mobilePhoneNo'
    },
    {
      label: '职位',
      prop: 'dutiesName'
    },
    {
      label: '人员类别',
      prop: 'personTypeName'
    }

  ]
}
// 个人担保关系子表格
export const relationshipTableConfig = (content) => {
  return [

    {
      prop: 'implementSituation',

      width: '120px'
    },
    {
      label: '姓名',
      prop: 'personnalName'
    },
    {
      label: '身份证',
      prop: 'idCard'
    },
    {
      label: '电话号码',
      prop: 'mobilePhoneNo'
    },
    {
      label: '关系',
      prop: 'contactType',
      formatter: row => {
        const [obj] = getDictLists('PERSON_CONTACT_TYPE').filter(item => item.dictId === row.contactType)
        return obj ? obj.dictName : '-'
      }
    }
  ]
}
// 新增个人担保表格
export const addTableConfig = (content) => {
  return [
    {
      label: '',
      prop: 'code',
      type: 'expand'
    },
    {
      label: '姓名',
      prop: 'personnerName'
    },
    {
      label: '身份证',
      prop: 'idCard'
    },
    {
      label: '电话号码',
      prop: 'mobilePhoneNo'
    },
    {
      label: '职位',
      prop: 'dutiesName'
    },
    {
      label: '家庭地址',
      prop: 'address'
    },
    {
      label: '人员类别',
      prop: 'personTypeCode'
    },
    {
      label: '操作',
      prop: 'action'
    }
  ]
}
// 新增个人担保关系子表格
export const addRelationTableConfig = () => {
  return [

    {
      label: '姓名',
      prop: 'personnalName'
    },
    {
      label: '身份证',
      prop: 'idCard'
    },
    {
      label: '电话号码',
      prop: 'mobilePhoneNo'
    },
    {
      label: '家庭地址',
      prop: 'address'
    },
    {
      label: '关系',
      prop: 'contactType'
    },
    {
      label: '操作',
      prop: 'action'
    }
  ]
}
